import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const FreightBillCharge = (props) => {

    const amount = ('NetCost' in props.costData) ? parseFloat(props.costData.NetCost.CurrencyValue).toFixed(2) : null;
    const accCode = ('AccessorialCode' in props.costData) ? props.costData.AccessorialCode.AccCode : null;

    return (
      <tr key={ props.id }>
        <td>
          <select name={ "freightBill[charges][" + props.id + "][costType]" } id="" className="load__form--table-input" defaultValue={ props.costData.CostType }>
            <option value="Accessorial">Accessorial</option>
            <option value="Freight">Freight</option>
          </select>
        </td>
        <td>
          <select name={ "freightBill[charges][" + props.id + "][costAccCode]" } id="" className="load__form--table-input" defaultValue={ accCode }>
            <option value=""></option>
            <option value='SD100'>$100 Special Delivery Charge</option>
            <option value='SDC50'>$50 Special Delivery Charge</option>
            <option value='FSCA'>Additional Fuel Surcharge</option>
            <option value='ADHS'>Additional Handling</option>
            <option value='PMT'>After Hours Service</option>
            <option value='ARB'>Arbitrary Charge</option>
            <option value='BCF'>Border Crossing Fee</option>
            <option value='BLK'>Bulkhead Charge</option>
            <option value='CAP'>Capacity Surcharge</option>
            <option value='EBD'>Convention Delivery</option>
            <option value='EBP'>Convention Pickup</option>
            <option value='Corrected BOL'>Corrected BOL</option>
            <option value='CDC'>Crossdock Charge</option>
            <option value='DED'>Dead Head</option>
            <option value='DASAK'>Delivery Surcharge ? AK</option>
            <option value='DASCONUS'>Delivery Surcharge ? Cont. US</option>
            <option value='DASHI'>Delivery Surcharge ? HI</option>
            <option value='DET'>Detention</option>
            <option value='DTL'>Detention Loading</option>
            <option value='DTU'>Detention Unloading</option>
            <option value='HAN'>Driver Unloading Charges</option>
            <option value='EXM'>Excess Mileage</option>
            <option value='DASEXCONUS'>Extended Surcharge ? Cont. US</option>
            <option value='FSC'>Fuel Surcharge</option>
            <option value='HAZ'>Hazardous Materials Charge</option>
            <option value='HCS'>High Cost Service Area</option>
            <option value='HRS'>Hourly Service</option>
            <option value='011'>Inbound Charge</option>
            <option value='INSD'>Inside Delivery</option>
            <option value='INSP'>Inside Pickup Charge</option>
            <option value='DASINTRAHI'>Intra HI Surcharge</option>
            <option value='LAT'>Late Fee</option>
            <option value='LAY'>Layover Fee</option>
            <option value='LFT'>Lift Gate Charge</option>
            <option value='LUM'>Lumper Service</option>
            <option value='JKCM'>Money Code Transfer</option>
            <option value='OVERSIZE'>Oversize</option>
            <option value='PLT'>Pallet Charge</option>
            <option value='PDS'>Peak Demand Surcharge</option>
            <option value='PERM'>Permits &amp; Escorts</option>
            <option value='REC'>Reconsignment Charge</option>
            <option value='RED'>Redelivery Charge</option>
            <option value='REM'>Remote Access Fee</option>
            <option value='RSC'>Reschedule Fee</option>
            <option value='RES'>Residential Surcharge</option>
            <option value='RET'>Return Charge</option>
            <option value='SAT'>Saturday Service</option>
            <option value='SSG'>Sort &amp; Segregate Charge</option>
            <option value='STOP'>Stop Charge</option>
            <option value='STR'>Storage Fees</option>
            <option value='SCBL'>Summit Cold BOL Charge</option>
            <option value='SCDC'>Summit Cold Crossdock Charge</option>
            <option value='SCPC'>Summit Cold Placard Charge</option>
            <option value='TEM'>Team Driver</option>
            <option value='TONU'>Truck Ordered Not Used</option>
            <option value='WHS'>Warehousing Fee</option>
            <option value='WI'>Weight Inspection</option>
          </select>
        </td>
        <td>
          <input type="text" name={ "freightBill[charges][" + props.id + "][costDescription]" } className="load__form--table-input" defaultValue={ props.costData.CostDetails } />
        </td>
        <td>
          <input type="number" name={ "freightBill[charges][" + props.id + "][costAmount]" } step=".01" className="load__form--table-input" defaultValue={ amount } />
        </td>
        <td className="text-center text-red">
            <FontAwesomeIcon icon={ faTrash } onClick={ () => props.delete(props.id) } className="pointer" />
        </td>
      </tr>
    )

};

export default FreightBillCharge;
