import {useState,useContext} from 'react';
import {UserContext} from '../../context/UserContext';
import {Button, Input, InputLabel, FormControl } from '@mui/material'
import './Login.css'


const Login = () => {
    const {loginUser, wait, loggedInCheck} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        action: 'login',
        username:'',
        password:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await loginUser(formData);

        if(data.success){
            e.target.reset();
            setRedirect('Redirecting...');
            await loggedInCheck();
            return true;
        }
        setErrMsg(data.message);
    }

    return (
        <div className="login__wrapper">
            <h2 className="login__h2">PPU Login</h2>
            <form onSubmit={submitForm}>
                <div className="load__form--group">
                    <FormControl variant="standard">
                        <InputLabel htmlFor="username">Username</InputLabel>
                        <Input name="username" defaultValue={ formData.username } id="username" onChange={ onChangeInput } required />
                    </FormControl>
                </div>
                <div className="load__form--group">
                    <FormControl variant="standard">
                        <InputLabel htmlFor="username">Password</InputLabel>
                        <Input type="password" name="password" defaultValue={ formData.password } id="password" onChange={ onChangeInput } required />
                        <input type="hidden" name="action" defaultValue="login" id="action" />
                    </FormControl>
                </div>
                {errMsg && <div className="err-msg">{errMsg}</div>}
                {redirect ? redirect : <Button sx={{ width: '100%', mt: 2 }} variant="contained" type="submit" disabled={wait}>Login</Button>}
            </form>
        </div>
    )
}

export default Login;