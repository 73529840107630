import React from 'react'
import {useDropzone} from 'react-dropzone'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import './FileLoader.css'

function FileLoader(props) {

  const [isLoading, setIsLoading] = React.useState(false)
  const [uploadError, setUploadError] = React.useState(false)

  const onDrop = async(acceptedFiles) => {

    setIsLoading(true)
    if (acceptedFiles.length > 0){
      try 
      {

        var formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('action', 'splitterFileUpload')

        //const requestURL = 'http://tdmkapi.us-east-2.elasticbeanstalk.com/request.php'

        // ==========================================================
        // TESTING
        // ==========================================================

        //const requestURL = 'http://localhost:7777/request.php';


        // ==========================================================
        // PRODUCTION!
        // ==========================================================

        const requestURL = 'https://api.trademarktrans.com/request.php';


        const {data} = await axios.post(requestURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        setIsLoading(false)

        if (data.successful)
        {
            return props.handleSuccessfulUpload(data.filepath, data.pages)
        }
        else
        {
            return setUploadError("Error: the file could not be uploaded!")
        }

      }
      catch(err){
        return {success:0, message:'Server Error!'};
      }
    }

  }

  const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({accept: 'application/pdf', onDrop})

  const fileRejectionItems = fileRejections.map(({ file, errors}) => (
      <li key={file.path} className="unstyled__ul">
          <strong>{file.path} - {file.size} bytes</strong>
          <ul className="unstyled__ul">
            { errors.map (e => (
              <li key={e.code}>{e.message}</li>
            ))}
          </ul>
      </li>
  ))

  return (
    <div {...getRootProps()} className="file__dropzone">
      <input {...getInputProps()} />

      { isLoading ? (
        <>
          <CircularProgress size={ 100 } />
          <p>Uploading file to server... please wait</p>
        </>
      ) : (
        <>
          {
            isDragActive ?
              <p>Drop here</p> :
              <p>Drop Load Paperwork PDF file here, or click to find file</p>
          }
        </>
      )}

      <aside className="aside__warning">
        { fileRejectionItems }
        { uploadError }
      </aside>
    </div>
  )
}

export default FileLoader;
