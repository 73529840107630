import React from 'react';
import { FormControl, InputLabel, Input, InputAdornment, CircularProgress, Button } from '@mui/material'
import axios from 'axios'
import './LoadSearcher.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const LoadSearcher = (props) => {

    const [isSearching, setIsSearching] = React.useState(false);
    const [hasMatch, setHasMatch] = React.useState(false);
    const [loadNum, setLoadNum] = React.useState(null);
    const [noLoadFoundError, setNoLoadFoundError] = React.useState(false);

    const handleChange = (e) => {

        setNoLoadFoundError(false);
        setHasMatch(false);
        setLoadNum(e.target.value);
        
        // Run the parent change function
        props.handleChange(e.target.value, false, {})
    }

    const handleLoadSearch = async () => {

        setIsSearching(true);

        try 
        {

            //const requestURL = 'http://tdmkapi.us-east-2.elasticbeanstalk.com/request.php'
            const requestURL = 'https://api.trademarktrans.com/request.php'
            //const requestURL = 'http://localhost:7777/request.php'

            const {data} = await axios.get(requestURL, { params: {
                'action': 'getLoadPaperworkInfo',
                'loadNum': loadNum
            }});

            setIsSearching(false);
            setNoLoadFoundError(!data['outcome'])
            setHasMatch(data['outcome'])
            
            console.log(data)

            props.handleChange(loadNum, data['outcome'], data.loadData)
        
        }
        catch(err){

            return {success:0, message:'Server Error!'};
        }

    }

  return (

    <FormControl variant="standard" sx={{ minWidth: 300 }}>
        <InputLabel htmlFor="input-with-icon-adornment">
            Load Number
        </InputLabel>
        <Input onChange={ handleChange } defaultValue={ props.loadNum } name="loadNum" id="loadNum" endAdornment={ isSearching ? ( 
            <InputAdornment position="end">
                <CircularProgress size={ 15 } />
            </InputAdornment>
            ) : noLoadFoundError ? (
            <InputAdornment position="end">
                <span className="bold text-red">No Load Found</span>
            </InputAdornment>) 
            : hasMatch ? (
            <InputAdornment position="end">
                <FontAwesomeIcon icon={ faCheckCircle } className="text-green" />
            </InputAdornment>) 
            : (
            <InputAdornment position="end">
            <Button variant="text" onClick={ handleLoadSearch }>Search</Button>
            </InputAdornment>)
        }/>
    </FormControl>

  );
};

export default LoadSearcher;
